import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import type { ErrorResponse } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { AppConfig } from '@drugfreesleep/config/AppConfig';

const OPERATIONS_NOT_REQUIRING_AUTH = ['AuthenticateUser', 'UserRegistration'];

let expiredAuthHandler: (errObj: ErrorResponse) => void;
export const setExpiredAuthHandler = (handler: typeof expiredAuthHandler) => {
  expiredAuthHandler = handler;
};

const errorDispatcher = onError((errObj) => {
  if (expiredAuthHandler) {
    expiredAuthHandler(errObj);
  }
});

let jwtProvider: () => string | null;
export const setJwtProvider = (provider: typeof jwtProvider) => {
  jwtProvider = provider;
};

function createApolloClient() {
  const authLink = setContext((req, { headers, anon }) => {
    if (
      anon ||
      OPERATIONS_NOT_REQUIRING_AUTH.includes(req.operationName as string)
    ) {
      return { headers };
    }

    // get the authentication token from local storage if it exists
    const token = jwtProvider ? jwtProvider() : null;

    if (!token) {
      return { headers };
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = new HttpLink({
    uri: AppConfig.graphql_url,
    credentials: 'same-origin',
  });

  return new ApolloClient({
    link: from([errorDispatcher, authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    connectToDevTools: true,
  });
}

let client: ApolloClient<any>;
export const getApolloClient = () => {
  if (!client) {
    client = createApolloClient();
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      (window as any).__APOLLO_CLIENT__ = client;
    }
  }
  return client;
};
