import Link from 'next/link';

import { Background } from '../components/background/Background';
import { Section } from '../components/layout/Section';
import { NavbarMenuCenter } from '../components/navigation/NavbarMenuCenter';
import { Logo } from './Logo';

const Navbar = () => (
  <Background color="bg-yellow-50">
    <Section yPadding="py-6">
      <NavbarMenuCenter
        logo={<Logo xl />}
        bottomMenu={
          <>
            {/* <li className="hidden">
              <Link href="/">Login</Link>
            </li> */}
            {/* <li>
              <Link href="/course">
                <Button>Get Started</Button>
              </Link>
            </li> */}
          </>
        }
      >
        <>
          <li>
            <Link href="/">Home</Link>
          </li>
          <li>
            <Link href="/">About</Link>
          </li>
          <li>
            <Link href="/sign-in">The Adamo Method</Link>
          </li>
          <li>
            <Link href="/">Blog</Link>
          </li>
          <li>
            <Link href="/">FAQ</Link>
          </li>
        </>

        {/* <li>
          <Link href="/">Treatments</Link>
        </li> */}
      </NavbarMenuCenter>
    </Section>
  </Background>
);

export { Navbar };
