import { Flowbite } from 'flowbite-react';
import type { FC, PropsWithChildren } from 'react';

import { customTheme } from './customTheme';

const FlowbiteContext: FC<PropsWithChildren> = ({ children }) => {
  let dark = false;
  if (typeof window !== 'undefined') {
    if (localStorage.theme !== 'light') {
      dark =
        localStorage.theme === 'dark' ||
        window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }

  return <Flowbite theme={{ dark, theme: customTheme }}>{children}</Flowbite>;
};

export default FlowbiteContext;
