export const AppConfig = {
  api_url: process.env.NEXT_PUBLIC_API_URL || 'https://api.lifeisachance.com',
  graphql_url:
    process.env.NEXT_PUBLIC_GRAPHQL_URL ||
    'https://api.lifeisachance.com/graphql',
  site_name: 'Adamo',
  title: 'The Adamo Method',
  description:
    'Deeper intimacy and healthier relationships with the science of human connection.',
  locale: 'en',
  configNoteId: process.env.NEXT_PUBLIC_CONFIG_NOTE_ID
    ? parseInt(process.env.NEXT_PUBLIC_CONFIG_NOTE_ID, 10)
    : 54,
};
