'use client';

import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import { Meta } from '../../components/layout/Meta';
import { AppConfig } from '../../config/AppConfig';
import { AccessCode } from './AccessCode';

const CODE = 'adamo';

const AccessCodeEnforcer = ({ children }: { children: ReactNode }) => {
  const [loaded, setLoaded] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  useEffect(() => {
    const code = localStorage.getItem('accessCode');
    if (code && accessCode === '') {
      setAccessCode(code);
    } else {
      localStorage.setItem('accessCode', accessCode);
    }
    setLoaded(true);
  }, [accessCode]);

  if (!loaded) {
    return <React.Fragment />;
  }

  if (accessCode.toLocaleLowerCase().trim() !== CODE) {
    return (
      <div className="text-gray-600 antialiased">
        <Meta title={AppConfig.title} description={AppConfig.description} />
        <AccessCode
          onSubmit={(value) => {
            setAccessCode(value);
          }}
          wrongAccessCode={accessCode.length > 0 ? accessCode : undefined}
        />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export { AccessCodeEnforcer };
