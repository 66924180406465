import cx from 'classnames';
import * as React from 'react';
import { useState } from 'react';

interface IProps {
  onSubmit: (accessCode: string) => void;
  wrongAccessCode?: string;
}

/**
 * @param props: { onSubmit: (accessCode: string) => void}
 * @param wrongAccessCode: string
 */
const AccessCode = (props: IProps) => {
  const [accessCode, setAccessCode] = useState('');
  return (
    <section className="h-screen">
      <div className="h-full bg-[url('/assets/images/backgroundColors.webp')] bg-cover bg-no-repeat px-6 text-gray-800">
        <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between xl:justify-center">
          <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
            <img
              src="/assets/images/Adamo_Master_Logo.webp"
              className="m-auto max-w-lg"
              alt="Sample image"
            />
          </div>
          <div className="mb-12 bg-white p-10 md:mb-0 md:w-8/12 lg:w-5/12 xl:ml-20 xl:w-5/12">
            <form
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                props.onSubmit(accessCode);
              }}
            >
              <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300">
                <p className="mx-4 mb-0 text-center font-semibold">Preview</p>
              </div>
              {/* < />!-- Access code input --> */}
              <div className="mb-6">
                <input
                  type="text"
                  value={accessCode}
                  className={cx(
                    {
                      'border-red-500 focus:border-red-500':
                        props.wrongAccessCode === accessCode,
                    },
                    'form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none'
                  )}
                  id="exampleFormControlInput2"
                  placeholder="Access code"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAccessCode(e.target.value);
                  }}
                />
              </div>

              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className="inline-block rounded bg-blue-600 px-7 py-3 text-sm font-medium uppercase leading-snug text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                  // Pass access code to props.onSubmit
                >
                  Enter
                </button>
                <p
                  className={cx(
                    { hidden: props.wrongAccessCode !== accessCode },
                    'mt-2 mb-0 pt-1 text-sm font-semibold'
                  )}
                >
                  Don&apos;t have an access code?
                  <a
                    href="https://www.linkedin.com/in/cesartoscano/"
                    target={'_blank'}
                    className="p-1 text-blue-600 transition duration-200 ease-in-out hover:text-blue-700 focus:text-blue-700"
                    rel="noreferrer"
                  >
                    Contact
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export { AccessCode };
