'use client';

import { Background } from '@drugfreesleep/components/background/Background';
import { Button } from '@drugfreesleep/components/button/Button';
import { AccessCodeEnforcer } from '@drugfreesleep/modules/authentication/AccessCodeEnforcer';
import Image from 'next/image';
import Link from 'next/link';
import couple1 from 'public/assets/images/couple1.webp';
import couple2 from 'public/assets/images/couple2.webp';
import couple4 from 'public/assets/images/couple4.webp';
import couple5 from 'public/assets/images/couple5.webp';

import { Meta } from '../components/layout/Meta';
import { AppConfig } from '../config/AppConfig';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const Base = () => {
  return (
    <AccessCodeEnforcer>
      <div className="text-gray-600 antialiased">
        <Meta title={AppConfig.title} description={AppConfig.description} />
        <Navbar />

        <div
          id="vision"
          style={{ paddingTop: '200pt', marginTop: '-200pt' }}
          className="overflow-hidden"
        >
          <Background color="">
            <div
              className="overflow-hidden bg-cover py-0"
              style={{
                height: '500px',
                backgroundImage: `url("/assets/images/backgroundColors.webp")`,
              }}
            >
              <div className="flex w-full justify-center">
                <div className="w-1/2 flex-col justify-center">
                  <div className="flex justify-center">
                    <div
                      className="grow bg-cover"
                      style={{
                        height: '500px',
                        backgroundImage: `url("/assets/images/coupleCrop.png")`,
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className="w-1/2 flex-col justify-center text-center"
                  style={{ paddingTop: '200pt', marginTop: '-200pt' }}
                >
                  <div className="h-full py-10 text-lg font-bold  text-black">
                    THE ADAMO METHOD
                    <div className="p-5 font-serif text-2xl font-normal lg:text-5xl">
                      Deeper intimacy and healthier relationships with the
                      science of human connection.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Background>
        </div>
        <Background color="bg-yellow-50">
          <div className="py-24 px-20">
            <div className="flex w-full justify-center">
              <div
                className="w-1/2 flex-col justify-center text-black"
                style={{ paddingTop: '200pt', marginTop: '-200pt' }}
              >
                <div className="font-serif text-5xl font-normal">
                  The quality of our relationships is the most significant
                  indicator of the quality of our lives.
                  <div className="font-sans text-lg font-normal">
                    Above smoking, drinking, exercise and a healthy diet; our
                    relationships have the greatest impact on the length and
                    health of our lives. <br />
                    <br />
                    However, more than ever, we are disconnected from each
                    other, ourselves and our bodies. We are detached from our
                    ability to feel pleasure, to communicate our desires and to
                    connect through our hearts and bodies.
                    <br />
                    <br /> We all seek love, deep bonds and access to pleasure.
                    We crave intimacy that has the ability to transform us and
                    strengthen our relationship. We know it, yet we struggle to
                    find it. Where do we go to learn how to have deeper
                    relationships and more pleasure?
                    <br /> <br />
                    <span className="font-bold">Enter Adamo.</span>
                  </div>
                  <div className=" flex justify-around">
                    <Link href="/course">
                      <Button secondary={true}>
                        Learn more about our mission
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex-col justify-center">
                <div className="flex justify-center">
                  <div className="relative mb-5">
                    <Image
                      src={couple1}
                      alt=""
                      width={439}
                      height={514}
                      className={`stroke-current `}
                    />
                    <div
                      className="relative float-right"
                      style={{ top: '-150px', right: '-30px' }}
                    >
                      <Image
                        src={couple2}
                        alt=""
                        width={325}
                        height={217}
                        className={`stroke-current `}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Background>
        <div>
          <div
            className="py-24 px-20"
            style={{
              backgroundImage: `url("/assets/images/backgroundColors.webp")`,
              backgroundSize: '100% 100%',
            }}
          >
            <div className="flex w-full justify-center">
              <div className="flex w-1/2 flex-col items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src={couple4}
                    alt=""
                    width={257}
                    height={286}
                    className="mb-5"
                  />
                  <Image
                    src={couple5}
                    alt=""
                    width={427}
                    height={435}
                    className="grayscale"
                  />
                </div>
              </div>
              <div
                className="w-1/2 flex-col justify-center text-right text-black"
                style={{ paddingTop: '200pt', marginTop: '-200pt' }}
              >
                <div className="font-serif text-5xl font-normal">
                  The Adamo Method is an innovative intimacy program for
                  couples.
                  <div className="text-lg font-normal">
                    Sex therapy hasn’t been reinvented since the 1960s.
                    <br />
                    We decided it was time.
                    <br />
                    <br />
                    The Adamo Method teaches a new way; one that not only
                    increases physical pleasure but cultivates a new level of
                    connection and intimacy with your partner.
                    <br />
                    <br /> Our proprietary method includes tools and exercises
                    for both the individual and couple in a beautiful digital
                    space. With top experts in the fields of medicine,
                    psychology, and somatic sexuality, we ensure a safe and
                    supported environment throughout the entire journey.
                    <br /> <br />
                    We say goodbye to disconnected, friction-based intercourse
                    and hello to the power of genuine human connection and
                    blissful intimacy on every level
                  </div>
                  <div className=" flex justify-around">
                    <Link href="/course">
                      <Button secondary={true}>
                        Discover the Adamo Method
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ManagedComponent slug="homepage" /> */}
        {/* <Hero /> */}
        {/* <Stats /> */}
        {/* <Features {...exampleFeaturesProps} /> */}
        {/* <VerticalFeatures /> */}
        {/* <Testimonial /> */}
        {/* <Pricing /> */}
        {/* <FAQ /> */}
        {/* <Banner /> */}
        <Footer />
      </div>
    </AccessCodeEnforcer>
  );
};

export { Base };
