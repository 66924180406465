module.exports = {
  "props": {
    "apolloCache": {
      "Note:54": {
        "__typename": "Note",
        "id": 54,
        "userId": 21,
        "orgId": 4,
        "type": "note",
        "state": "DRAFT",
        "name": "Adamo Config",
        "slug": "",
        "payload": {
          "md": "",
          "program": {
            "id": 3,
            "slug": "adamo",
            "versionId": -1,
            "organizationSlug": "adamo"
          },
          "components": {
            "about": {
              "id": 55
            },
            "homepage": {
              "id": 56
            }
          }
        },
        "meta": {},
        "isPublic": true,
        "createdAt": "2024-03-22T01:58:18.056383",
        "updatedAt": "2024-03-31T17:23:26.110638",
        "atVersion({\"input\":{}})": {
          "__typename": "Note",
          "userId": 21,
          "orgId": 4,
          "type": "note",
          "state": "DRAFT",
          "name": "Adamo Config",
          "slug": "",
          "payload": {
            "md": "",
            "program": {
              "id": 3,
              "slug": "adamo",
              "versionId": 47,
              "organizationSlug": "adamo"
            },
            "components": {
              "about": {
                "id": 55
              },
              "homepage": {
                "id": 56
              }
            }
          },
          "meta": {},
          "isPublic": true,
          "createdAt": "2024-03-22T01:58:18.056383",
          "updatedAt": "2024-03-22T12:08:48.995371"
        }
      },
      "ROOT_QUERY": {
        "__typename": "Query",
        "note({\"id\":54})": {
          "__ref": "Note:54"
        }
      }
    }
  }
};