import Link from 'next/link';

import { Background } from '../components/background/Background';
import { FooterLinks } from '../components/footer/FooterLinks';
import { FooterTwoRowsCopyright } from '../components/footer/FooterTwoRowsCopyright';
import { Section } from '../components/layout/Section';
import { AppConfig } from '../config/AppConfig';

const Footer = () => (
  <Background color="bg-yellow-50">
    <Section>
      <FooterTwoRowsCopyright siteName={AppConfig.site_name}>
        {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
        <div className="grid hidden grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-5">
          <FooterLinks title="Product">
            <li>
              <Link href="/">First link</Link>
            </li>
            <li>
              <Link href="/">Second link</Link>
            </li>
            <li>
              <Link href="/">Third link</Link>
            </li>
            <li>
              <Link href="/">Forth link</Link>
            </li>
          </FooterLinks>
          <FooterLinks title="Resources">
            <li>
              <Link href="/">First link</Link>
            </li>
            <li>
              <Link href="/">Second link</Link>
            </li>
            <li>
              <Link href="/">Third link</Link>
            </li>
            <li>
              <Link href="/">Forth link</Link>
            </li>
          </FooterLinks>
          <FooterLinks title="Contact">
            <li>
              <Link href="/">First link</Link>
            </li>
            <li>
              <Link href="/">Second link</Link>
            </li>
            <li>
              <Link href="/">Third link</Link>
            </li>
            <li>
              <Link href="/">Forth link</Link>
            </li>
          </FooterLinks>
          <FooterLinks title="Legal">
            <li>
              <Link href="/">First link</Link>
            </li>
            <li>
              <Link href="/">Second link</Link>
            </li>
            <li>
              <Link href="/">Third link</Link>
            </li>
            <li>
              <Link href="/">Forth link</Link>
            </li>
          </FooterLinks>
          <FooterLinks title="Press">
            <li>
              <Link href="/">First link</Link>
            </li>
            <li>
              <Link href="/">Second link</Link>
            </li>
            <li>
              <Link href="/">Third link</Link>
            </li>
            <li>
              <Link href="/">Forth link</Link>
            </li>
          </FooterLinks>
        </div>
      </FooterTwoRowsCopyright>
    </Section>
  </Background>
);

export { Footer };
